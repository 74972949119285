import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/Hero/Hero"
import FlexibleContentStandard from "../../components/FlexibleContentStandard/FlexibleContentStandard"

const Page = ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    heading,
    headingTranslated,
    subheading,
    text,
    image,
    flexibleContentStandard,
  } = data.page

  return (
    <Layout>
      <Seo title={metaTitle || heading} description={metaDescription} />
      <Hero
        heading={heading}
        headingTranslated={headingTranslated}
        subheading={subheading}
        text={text}
        image={image[0]}
      />

      {flexibleContentStandard &&
        flexibleContentStandard.map((item, index) => {
          const isFirst = index === 0
          const isLast = index === flexibleContentStandard.length - 1
          const hasSamePrevBgColour =
            flexibleContentStandard[index - 1]?.backgroundColour ===
            item.backgroundColour
          const hasSameNextBgColour =
            flexibleContentStandard[index + 1]?.backgroundColour ===
            item.backgroundColour

          return (
            <FlexibleContentStandard
              key={item.id}
              isFirst={isFirst}
              isLast={isLast}
              hasSamePrevBgColour={hasSamePrevBgColour}
              hasSameNextBgColour={hasSameNextBgColour}
              {...item}
            />
          )
        })}
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageQuery($uri: String) {
    page: craftPagesDefaultEntry(uri: { eq: $uri }) {
      metaTitle
      metaDescription
      heading: title
      headingTranslated: translatedTitle
      subheading: textPrimary
      text: textSecondary
      image {
        ... on Craft_images_Asset {
          ...imageFragment
        }
      }
      flexibleContentStandard {
        ...flexibleContentStandardFragment
      }
    }
  }
`
